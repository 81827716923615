import { FC, useRef } from 'react';
import {
  FilledInput,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';

import { SelectFormControlObjectProps } from './types';
import { useSelectFormControlStyles } from './styles';

export function SelectFormControlObject<T>(props: SelectFormControlObjectProps<T>) {
  const {
    label,
    placeholder,
    variant,
    disabled,
    inputProps = {},
    selectProps = {} as SelectProps,
    selectedOption,
    options,
    getOptionValue,
    getOptionLabel = getOptionValue,
    onOptionSelect,
  } = props;
  const classes = useSelectFormControlStyles();

  const inputLabelRef = useRef(null);

  const input =
    variant === 'outlined' ? (
      <OutlinedInput label={label} name='input' id='input' {...inputProps} />
    ) : variant === 'filled' ? (
      <FilledInput name='input' id='input' {...inputProps} />
    ) : (
      <Input name='input' id='input' {...inputProps} />
    );

  const handleSelectOption = (event: SelectChangeEvent<unknown>) => {
    const selected = options.find((option: T) => getOptionValue(option) === event.target.value);

    if (selected !== undefined) {
      onOptionSelect(selected);
    }
  };

  return (
    <FormControl variant={variant} disabled={disabled || options.length === 0}>
      {label && (
        <InputLabel ref={inputLabelRef} htmlFor='currency'>
          {label}
        </InputLabel>
      )}

      <Select
        {...selectProps}
        classes={{
          select: classes.selectItem,
          ...selectProps.classes,
        }}
        value={selectedOption !== undefined ? getOptionValue(selectedOption) : ''}
        onChange={handleSelectOption}
        input={input}
        data-testid={`${label?.toLowerCase() || 'entity'}-select-label`}
        displayEmpty={true}
      >
        {placeholder && (
          <MenuItem value='' disabled={true}>
            {placeholder}
          </MenuItem>
        )}
        {options.map((opt: T, idx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem key={idx} value={getOptionValue(opt)} className={classes.item}>
            {getOptionLabel(opt)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function ofType<T>() {
  return SelectFormControlObject as FC<SelectFormControlObjectProps<T>>;
}

SelectFormControlObject.ofType = ofType;
