import { IconButton } from '@mui/material';
import { ArrowForwardIosOutlined } from '@mui/icons-material';
import { useCallback, useState } from 'react';

import { sidebarClasses } from '../styles';
import { IUseDefaultTogglerResult } from '../types';

export const useDefaultToggler = (isSidebarOpenInitiallyByDefault: boolean): IUseDefaultTogglerResult => {
  const [isOpenedByDefaultToggler, setIsOpenedByDefaultToggler] = useState<boolean>(isSidebarOpenInitiallyByDefault);
  const [isDefaultTogglerHovered, setIsDefaultTogglerHovered] = useState<boolean>(false);

  const toggleIsOpenedByDefaultTogglerOn = useCallback(() => {
    setIsOpenedByDefaultToggler(true);
  }, []);

  const toggleIsOpenedByDefaultTogglerOff = useCallback(() => {
    setIsOpenedByDefaultToggler(false);
  }, []);

  const handleDefaultTogglerMouseover = () => {
    if (!isOpenedByDefaultToggler) {
      setIsDefaultTogglerHovered(true);
    }
  };

  const handleDefaultTogglerMouseout = () => {
    setIsDefaultTogglerHovered(false);
  };

  const sidebarDefaultToggler: JSX.Element = (
    <IconButton
      onClick={isOpenedByDefaultToggler ? toggleIsOpenedByDefaultTogglerOff : toggleIsOpenedByDefaultTogglerOn}
      onMouseOver={handleDefaultTogglerMouseover}
      onMouseOut={handleDefaultTogglerMouseout}
      className={`${sidebarClasses.defaultToggler} ${
        !isOpenedByDefaultToggler ? sidebarClasses.defaultTogglerClosed : ''
      }`}
      size='large'
    >
      <ArrowForwardIosOutlined className={sidebarClasses.defaultTogglerArrowIcon} />
      <ArrowForwardIosOutlined className={sidebarClasses.defaultTogglerArrowIcon} />
    </IconButton>
  );

  return {
    defaultToggler: sidebarDefaultToggler,
    isOpenedByDefaultToggler,
    isDefaultTogglerHovered,
    toggleIsOpenedByDefaultTogglerOn,
    toggleIsOpenedByDefaultTogglerOff,
  };
};
