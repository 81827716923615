import { makeStyles } from '@mui/styles';

import { PreviewSizes } from '/constants/file';

export const useSelectFormControlStyles = makeStyles({
  item: {
    textTransform: 'none',
  },
  itemIcon: {
    width: `${PreviewSizes.xsmall}px`,
    height: `${PreviewSizes.xsmall}px`,
    marginRight: 5,
  },
  selectItem: {
    display: 'flex',
    alignItems: 'center',
  },
});
