import { FC, useState, SyntheticEvent, ReactNode } from 'react';
import { Link, Popover, Stack, Typography } from '@mui/material';

import { SearchList, IDimensionsType, Row } from './SearchList';

interface ISearchListPopoverProps {
  label: ReactNode;
  itemList: string[];
  placeholder?: string;
  dimensions?: IDimensionsType;
  onItemSelect?: (item: string) => void;
  isLink?: boolean;
  hidden?: boolean;
  hiddenSearch?: boolean;
}

export const SearchListPopover: FC<ISearchListPopoverProps> = (props) => {
  const {
    label,
    itemList,
    dimensions,
    onItemSelect,
    placeholder = 'Find item',
    isLink = true,
    hidden = false,
    hiddenSearch = false,
  } = props;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleLinkClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    if (!hidden) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const onItemClick = (item: string) => (e: SyntheticEvent) => {
    e.stopPropagation();
    if (onItemSelect) {
      onItemSelect(item);
    }
    handlePopoverClose(e);
  };

  return (
    <>
      {isLink ? (
        <Link component='button' onClick={handleLinkClick}>
          {label}
        </Link>
      ) : (
        <div onClick={handleLinkClick}>{label}</div>
      )}
      <Popover
        id={anchorEl ? 'simple-popover' : 'null'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // https://github.com/petyosi/react-virtuoso/issues/522#issuecomment-1007514356
        TransitionProps={{ timeout: 0 }}
      >
        {hiddenSearch ? (
          <Stack spacing={1} p='8px 0'>
            {itemList.map((_, index) => (
              <Row key={index} index={index} list={itemList} onItemClick={onItemClick} />
            ))}
          </Stack>
        ) : (
          <Typography component='div'>
            <div>
              <SearchList
                placeholder={placeholder}
                onItemClick={onItemClick}
                itemList={itemList}
                dimensions={dimensions}
              />
            </div>
          </Typography>
        )}
      </Popover>
    </>
  );
};
