import { DrawerProps, PaperProps as IPaperProps } from '@mui/material';
import { MutableRefObject } from 'react';

export enum SidebarSide {
  Left = 'left',
  Right = 'right',
}

export enum SidebarTogglingAction {
  Opened,
  Closed,
}

export interface IManualTogglingParams {
  isOpenedManually: boolean;
  openManually: () => void;
  closeManually: () => void;
}

export interface ISidebarProps extends Omit<DrawerProps, 'anchor' | 'open'> {
  side: SidebarSide;
  isOpenInitiallyByDefault?: boolean;
  manualTogglingParams?: IManualTogglingParams;
  shouldBeOpenOnHover?: boolean;
  shouldShowLifeSignOnDefaultTogglerHover?: boolean;
  onTogglingStatusChange?: (isOpen: boolean) => void;
}

export interface ISidebarEmissionDataBase {
  sidebarIdentifier: MutableRefObject<undefined>;
  side: SidebarSide;
}

export interface ISidebarTogglingData extends ISidebarEmissionDataBase {
  recentAction: SidebarTogglingAction;
}

export interface IUseAutomaticTogglingParams {
  side: SidebarSide;
  isSidebarOpen: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
}

export interface IUseTogglingOnHoverResult {
  PaperProps: Partial<IPaperProps> | null;
  isOpenedByHovering: boolean;
}

export interface IStyledDrawerProps {
  $isToggledManually: boolean;
  $navSidebarSide: SidebarSide;
  $isNavSidebarPermanentlyOpen: boolean;
}

export interface IGetPseudoTogglingAnimationParams {
  elementType: 'drawer' | 'toggler';
  currentSidebarSide: SidebarSide;
  navSidebarSide: SidebarSide;
  isNavSidebarPermanentlyOpen: boolean;
}

export interface IUseDefaultTogglerResult {
  defaultToggler: JSX.Element | null;
  isOpenedByDefaultToggler: boolean;
  isDefaultTogglerHovered: boolean;
  toggleIsOpenedByDefaultTogglerOn: () => void;
  toggleIsOpenedByDefaultTogglerOff: () => void;
}
