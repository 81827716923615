export const SIDEBAR_ROUND_ICON_BUTTON_PADDING_PX = 28;

export const SIDEBAR_TRANSITION_CONFIG_DEFAULT = '150ms cubic-bezier(0.4, 0, 0.2, 1)';

export const SIDEBAR_WIDTH_DEFAULT_PX = 335;

export const SIDEBAR_PADDING_DEFAULT_PX = 12;

export const DEFAULT_TOGGLER_BUTTON_OFFSET_PX = 16;

export const SIDEBAR_Z_INDEX = 20;
