import { PaperProps as IPaperProps } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';

import { IUseTogglingOnHoverResult } from '../types';

export const useTogglingOnHover = (
  isPermanentlyOpen: boolean,
  shouldBeOpenOnHover: boolean
): IUseTogglingOnHoverResult => {
  const [isOpenedByHovering, setIsOpenedByHovering] = useState<boolean>(false);

  const handleHover = (isHovered: boolean) =>
    _.throttle(() => {
      if (!isPermanentlyOpen) {
        setIsOpenedByHovering(isHovered);
      }
    }, 500);

  const PaperProps: Partial<IPaperProps> = {
    onMouseEnter: handleHover(true),
    onMouseLeave: handleHover(false),
  };

  return {
    PaperProps: shouldBeOpenOnHover ? PaperProps : null,
    isOpenedByHovering,
  };
};
