import { FC, useEffect, useMemo, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { uiToolkit } from '/store';

import { ISidebarProps, SidebarSide } from './types';
import { useAutomaticToggling } from './hooks/useAutomaticToggling';
import { useTogglingOnHover } from './hooks/useTogglingOnHover';
import { sidebarClasses, StyledDrawer } from './styles';
import { useDefaultToggler } from './hooks/useDefaultToggler';

// @ts-expect-error type errors FIXME
export const Sidebar: FC<ISidebarProps> = forwardRef((props, ref) => {
  const {
    side,
    isOpenInitiallyByDefault = false,
    manualTogglingParams,
    shouldBeOpenOnHover = false,
    shouldShowLifeSignOnDefaultTogglerHover = false,
    onTogglingStatusChange,
    children,
    ...drawerProps
  }: ISidebarProps = props;

  const isNavSidebarPermanentlyOpen: boolean = useSelector(uiToolkit.selectors.navSidebarOpen);
  const navSidebarSide: SidebarSide = useSelector(uiToolkit.selectors.navSidebarSide);

  const { isOpenedManually, openManually, closeManually } = manualTogglingParams || {};

  const {
    defaultToggler,
    isOpenedByDefaultToggler,
    isDefaultTogglerHovered,
    toggleIsOpenedByDefaultTogglerOn,
    toggleIsOpenedByDefaultTogglerOff,
  } = useDefaultToggler(isOpenInitiallyByDefault);

  const isPermanentlyOpen: boolean = isOpenedManually !== undefined ? isOpenedManually : isOpenedByDefaultToggler;

  const { PaperProps, isOpenedByHovering } = useTogglingOnHover(isPermanentlyOpen, shouldBeOpenOnHover);

  const isOpen = useMemo<boolean>(() => {
    if (isOpenedByHovering) {
      return true;
    }

    return isPermanentlyOpen;
  }, [isOpenedByHovering, isPermanentlyOpen]);

  useAutomaticToggling({
    side,
    isSidebarOpen: isOpen,
    openSidebar: openManually || toggleIsOpenedByDefaultTogglerOn,
    closeSidebar: closeManually || toggleIsOpenedByDefaultTogglerOff,
  });

  useEffect(() => {
    if (!onTogglingStatusChange) {
      return;
    }

    onTogglingStatusChange(isOpen);
  }, [onTogglingStatusChange, isOpen]);

  return (
    <StyledDrawer
      ref={ref}
      classes={{
        root: `
          ${isOpen ? sidebarClasses.sidebarOpen : ''}
          ${
            shouldShowLifeSignOnDefaultTogglerHover && isDefaultTogglerHovered
              ? sidebarClasses.pseudoToggleAnimating
              : ''
          }
        `,
        paper: sidebarClasses.drawerPaper,
      }}
      open={isOpen}
      PaperProps={{ ...PaperProps }}
      anchor={side}
      $isToggledManually={Boolean(manualTogglingParams)}
      $navSidebarSide={navSidebarSide}
      $isNavSidebarPermanentlyOpen={isNavSidebarPermanentlyOpen}
      {...drawerProps}
      onOpen={openManually || toggleIsOpenedByDefaultTogglerOn}
      onClose={closeManually || toggleIsOpenedByDefaultTogglerOff}
    >
      {!manualTogglingParams ? defaultToggler : null}

      {children}
    </StyledDrawer>
  );
});
