import { FC, useState, ChangeEvent, MouseEvent, SyntheticEvent } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { InputAdornment, ListItem, Typography, TextField, IconButton, Box } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';

export interface IDimensionsType {
  height: number;
  width: number;
  itemSize: number;
}

interface ISearchListProps {
  itemList: string[];
  selectedItem?: string;
  placeholder: string;
  dimensions?: IDimensionsType;
  onItemClick: (version: string) => (e: SyntheticEvent) => void;
}

const defaultDimensions = { height: 200, width: 260, itemSize: 35 };

export const Row: FC<{
  index: number;
  list: string[];
  onItemClick: ISearchListProps['onItemClick'];
  height?: number;
}> = ({ index, list, onItemClick, height = defaultDimensions.itemSize }) => {
  const item = list[index];
  return (
    <ListItem button={true} onClick={onItemClick(list[index])} sx={{ height: height }}>
      <Typography variant='inherit' noWrap={true}>
        {item}
      </Typography>
    </ListItem>
  );
};

export const SearchList: FC<ISearchListProps> = (props) => {
  const { itemList, onItemClick, placeholder, selectedItem, dimensions = defaultDimensions } = props;
  const [value, setValue] = useState(selectedItem || '');
  const [list, setList] = useState(itemList);

  const onSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (!event.target.value) {
      return setList(itemList);
    }
    const filteredVersions: string[] = list.filter((version: string) => version.includes(event.target.value));
    setList(filteredVersions);
  };

  const onSearchClick = (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  const onClearClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setValue('');
    setList(itemList);
  };

  return (
    <Box width={dimensions.width} position='relative'>
      <TextField
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position='start'>
              {value ? (
                <IconButton onClick={onClearClick} size='large'>
                  <ClearIcon fontSize='small' />
                </IconButton>
              ) : (
                <SearchIcon color='disabled' />
              )}
            </InputAdornment>
          ),
        }}
        value={value}
        onClick={onSearchClick}
        onChange={onSearchInput}
        fullWidth={true}
        sx={{
          padding: '8px',
        }}
      />
      <Virtuoso
        style={{ height: dimensions.height, width: dimensions.width }}
        defaultItemHeight={dimensions.itemSize}
        totalCount={list.length}
        itemContent={(index) => (
          <Row index={index} list={list} onItemClick={onItemClick} height={dimensions.itemSize} />
        )}
      />
    </Box>
  );
};
